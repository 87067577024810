import React, { FC, useState } from 'react';
import momentTZ from 'moment-timezone';
import './Clock.scss';
import { IClock } from '../../../types/types';

const Clock: FC<IClock> = (props) => {
    const timeZone = momentTZ.tz(`${props.timezone}`).format('hh:mm A');

    const [currentTime, setCurrentTime] = useState(timeZone);

    const updateTime = () => {
        const newTimeZone = momentTZ.tz(`${props.timezone}`).format('hh:mm A');

        setCurrentTime(newTimeZone);
    };

    setInterval(updateTime, 1000);

    return (
        <span data-testid="clock" className="clock">
            {currentTime}
        </span>
    );
};

export default Clock;
